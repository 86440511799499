import revive_payload_client_h7hcFdHMgS from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.10.0_jiti@1.2_qley2ofp7efuqgbtr2tswrf5gq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tVLYU064LH from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.10.0_jiti@1.2_qley2ofp7efuqgbtr2tswrf5gq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_glZCIfsxnh from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.10.0_jiti@1.2_qley2ofp7efuqgbtr2tswrf5gq/node_modules/nuxt/dist/app/plugins/router.js";
import supabase_client_xN1bvT0pFK from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_cNpzRQDjeu from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.10.0_jiti@1.2_qley2ofp7efuqgbtr2tswrf5gq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2UIZRJ85oe from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.10.0_jiti@1.2_qley2ofp7efuqgbtr2tswrf5gq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3sLH6LcQTD from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.10.0_jiti@1.2_qley2ofp7efuqgbtr2tswrf5gq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1oYtNKhmhA from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.10.0_jiti@1.2_qley2ofp7efuqgbtr2tswrf5gq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_dEhk5VZPpm from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@20.14.15_terser@5.31_mmrjf2htzoonhqjic6in6tyb4a/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_2ea881KbPD from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@20.14.15_terser@5.31.5___2l3iwqlfa55sjt5nrkzt6sdtfm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import fathom_client_HlKuHmwBFW from "/vercel/path0/plugins/fathom.client.ts";
import init_client_lHDJZN4lq2 from "/vercel/path0/plugins/init.client.ts";
export default [
  revive_payload_client_h7hcFdHMgS,
  unhead_tVLYU064LH,
  router_glZCIfsxnh,
  supabase_client_xN1bvT0pFK,
  payload_client_cNpzRQDjeu,
  navigation_repaint_client_2UIZRJ85oe,
  check_outdated_build_client_3sLH6LcQTD,
  chunk_reload_client_1oYtNKhmhA,
  components_plugin_KR1HBZs4kY,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_dEhk5VZPpm,
  plugin_2ea881KbPD,
  fathom_client_HlKuHmwBFW,
  init_client_lHDJZN4lq2
]